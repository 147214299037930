import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  BlobStorage,
  PlanAnnotation,
  PlanQA,
  Rect,
} from "../../../model/blueprintServer";
import { useCanvas } from "../../canvas/useCanvas";
import { useRecoilValue } from "recoil";
import { pageImageSizeAtom } from "../../../atom/pageImageSizeAtom";
import { formatImageUrl } from "../../../util/imageUtil";
import { CanvasMenu } from "../../canvas/CanvasMenu";
import { planQaApi } from "../../../api/PlanQaApi";
import { useCurrenPlanQaData } from "../../../atom/planQaAtom";

import {
  CanvasAnnotation,
  planAnnotationToCanvasAnnotation,
} from "../../canvas/util/canvasAnnotationUtil";

interface PlanCanvasProps {
  pageImageBlob: BlobStorage;
  planQa: PlanQA;
  pageNumber: number;
}

export const PlanCanvas = ({
  pageImageBlob,
  planQa,
  pageNumber,
}: PlanCanvasProps) => {
  const [selectedAnnotationKey, setSelectedAnnotationKey] = useState<
    string | null
  >(null);
  const currentPlanQa = useCurrenPlanQaData();

  const handleAddAnnotation = (pageNumber: number, rect: Rect) => {
    planQaApi.addAnnotation(planQa.planQaId, pageNumber, rect).then((data) => {
      currentPlanQa.update(data);
      currentPlanQa.endLoading();
    });
  };
  const annotations = useMemo(
    () =>
      Object.keys(planQa.planAnnotation).reduce(
        (acc, key) => [
          ...acc,
          planAnnotationToCanvasAnnotation(planQa.planAnnotation[key]),
        ],
        [] as CanvasAnnotation[],
      ),
    [planQa.planAnnotation],
  );
  const { Canvas } = useCanvas(
    pageImageBlob,
    pageNumber,
    annotations,
    handleAddAnnotation,
    (key) => {
      setSelectedAnnotationKey(key);
    },
  );
  const { adjustedContainerWidth, adjustedContainerHeight } =
    useRecoilValue(pageImageSizeAtom);
  const [float, setFloat] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const target = document.getElementById("canvas");
      if (!target) {
        return;
      }

      const rect = target.getBoundingClientRect();
      const isBellow = !!rect && rect.bottom >= 0 && rect.y < 75;
      setFloat(isBellow);
    };

    document
      .getElementById("canvas-wrapper")
      ?.addEventListener("scroll", handleScroll);
    const refCopy = menuRef.current;

    return () => {
      refCopy?.removeEventListener("scroll", handleScroll);
    };
  }, [menuRef, setFloat]);

  return useMemo(() => {
    return (
      <div className="canvas-wrapper" id="canvas-wrapper">
        <CanvasMenu
          annotationKey={selectedAnnotationKey}
          resetAnnotationKey={() => setSelectedAnnotationKey(null)}
          menuRef={menuRef}
          float={float}
        />
        {float && <div className="canvas-rect-menu-placeholder" />}
        <div className="canvas">
          <img
            className="under-image"
            src={formatImageUrl(pageImageBlob)}
            style={{
              width: adjustedContainerWidth,
              height: adjustedContainerHeight,
            }}
          />
          {Canvas}
        </div>
      </div>
    );
  }, [
    float,
    Canvas,
    pageImageBlob,
    adjustedContainerWidth,
    adjustedContainerHeight,
    selectedAnnotationKey,
  ]);
};
