import { selector } from "recoil";
import { planReportFiltersAtom } from "./PlanReportFiltersAtom";
import {
  groupSections,
  sectionsComplianceReportFilter,
} from "../util/sectionsComplianceReportFilter";
import { currentPlanReportAtom } from "../../../atom/currentPlanReportAtom";
import { SectionComplianceReport } from "../../../model/blueprintServer";

export const filteredSectionComplianceReport = selector({
  key: "filteredSectionComplianceReport",
  get: ({ get }) => {
    const sections = get(currentPlanReportAtom).model;
    if (!sections) {
      return [] as SectionComplianceReport[];
    }

    return sectionsComplianceReportFilter(
      sections.sectionComplianceReportList,
      get(planReportFiltersAtom),
    );
  },
});

export const groupedFilteredSectionComplianceReport = selector({
  key: "groupedFilteredSectionComplianceReport",
  get: ({ get }) => groupSections(get(filteredSectionComplianceReport)),
});
