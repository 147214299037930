export const calculateLineThickness = (
  ctx: CanvasRenderingContext2D,
): number => {
  const canvasWidth = ctx.canvas.width;
  const canvasHeight = ctx.canvas.height;

  const scalingFactor = 1024 / 12;
  return Math.max(
    1,
    Math.round(Math.min(canvasWidth, canvasHeight) / scalingFactor),
  );
};

const MAX_FONT_SIZE = 400;

export const calculateFontSize = (ctx: CanvasRenderingContext2D): number => {
  const canvasWidth = ctx.canvas.width;
  const canvasHeight = ctx.canvas.height;

  const scalingFactor = 8;
  const calculatedSize = Math.max(
    1,
    Math.round(Math.min(canvasWidth, canvasHeight) / scalingFactor),
  );

  return Math.min(calculatedSize, MAX_FONT_SIZE);
};
