import React from "react";
import "./sideBySideContentForReport.scss";
import { ReviewResultTypeCategories } from "../planReport/reportSectionFilter/ReviewResultTypeFilter";
import { ReportListJourney } from "../planReport/reportListJourney/ReportListJourney";

export interface SectionComplianceFilter {
  searchText: string;
  categories: ReviewResultTypeCategories;
}

export const SideBySideContentForReport = () => {
  return (
    <div className="right-hand-size-for-report flex">
      <div className="selected-tab">
        <ReportListJourney />
      </div>
    </div>
  );
};
