import { CanvasAnnotation } from "../util/canvasAnnotationUtil";
import {
  calculateFontSize,
  calculateLineThickness,
} from "../util/canvasSizeUtil";
import { shadeColor } from "../../../util/colorUtil";

const textYPosition = (rectTopY: number, fontSize: number) =>
  rectTopY - fontSize > 0 ? rectTopY - 50 : rectTopY + fontSize + 20;

export const reactForAnnotation =
  (annotations: CanvasAnnotation[]) => (ctx: CanvasRenderingContext2D) => {
    let index = 0;
    for (const annotation of annotations) {
      const { start, end } = annotation.rect;
      const x = Math.min(start.x, end.x);
      const y = Math.min(start.y, end.y);
      const minX = Math.min(start.x, end.x);
      const minY = Math.min(start.y, end.y);
      const w = Math.max(start.x, end.x) - minX;
      const h = Math.max(start.y, end.y) - minY;
      const thickness = calculateLineThickness(ctx);

      ctx.lineWidth =
        annotation.isHovered || annotation.isSelected
          ? thickness * 1.5
          : thickness;
      ctx.strokeStyle = annotation.isSelected
        ? shadeColor(annotation.theme.color, 30)
        : annotation.theme.color;
      if (annotation.isSelected) {
        ctx.lineWidth = thickness;
        ctx.strokeStyle = shadeColor(annotation.theme.color, 90);
      } else if (annotation.isHovered) {
        ctx.lineWidth = thickness * 1.5;
        ctx.strokeStyle = shadeColor(annotation.theme.color, 90);
      } else {
        ctx.lineWidth = thickness;
        ctx.strokeStyle = shadeColor(annotation.theme.color, 0);
      }
      ctx.strokeRect(x, y, w, h);

      const fontSize = calculateFontSize(ctx);
      ctx.font = `${fontSize}px serif`;
      ctx.fillStyle = annotation.theme.color;
      ctx.fillText(annotation.label, minX + 20, textYPosition(minY, fontSize));
      index++;
    }
  };
