import React, { ReactNode, useEffect } from "react";
import "./modal.scss";

interface ModalProps {
  handleBackdropClick: () => void;
  children: ReactNode;
}

export const Modal = ({ handleBackdropClick, children }: ModalProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleBackdropClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleBackdropClick]);

  return (
    <div className="modal">
      <div className="backdrop" onClick={handleBackdropClick} />
      <div className="container">{children}</div>
    </div>
  );
};
