import { PlanAnnotation } from "../../model/blueprintServer";

import { CanvasAnnotation } from "./util/canvasAnnotationUtil";

export const annotationForPainter = (
  annotation: PlanAnnotation,
  { isSelected = false, isHovered = false } = {},
): CanvasAnnotation => {
  return {
    ...annotation,
    isSelected,
    isHovered,
    theme: { color: "blue" },
    componentId: "",
  };
};
