import { useCallback } from "react";
import {
  RESET_COMPONENT_IN_PAGE_INDEX_EVENT,
  SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT,
  ShowPageInPageView,
} from "../../markdown/PageRefLink";
import { useBrowserEventListenerEvent } from "../../../hooks/useBrowserEventListenerEvent";

export const useHighlightedPageAndComponentListener = (
  setPage: (pageIndex: number) => void,
  setSelectedComponentId: (componentId: string[]) => void,
) => {
  const handleShowPage = useCallback(
    (event: any) => {
      const { pageIndex, componentId } = event.detail as ShowPageInPageView;
      setPage(parseInt(pageIndex) - 1);
      setSelectedComponentId(componentId ? [componentId] : []);
    },
    [setPage, setSelectedComponentId],
  );

  const handleResetComponent = (event: any) => {
    setSelectedComponentId([]);
  };

  useBrowserEventListenerEvent(
    SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT,
    handleShowPage,
  );
  useBrowserEventListenerEvent(
    RESET_COMPONENT_IN_PAGE_INDEX_EVENT,
    handleResetComponent,
  );
};
