import { atom } from "recoil";
import { ReviewResultType } from "../../../model/blueprintServer";
import { ReviewResultTypeCategories } from "../reportSectionFilter/ReviewResultTypeFilter";

export interface PlanReportFiltersAtom {
  searchText: string;
  reviewResultTypeFilter: ReviewResultTypeCategories;
  pageIndex: number;
}

export const planReportFiltersAtom = atom<PlanReportFiltersAtom>({
  key: "PlanReportFiltersAtom",
  default: {
    searchText: "",
    pageIndex: 0,
    reviewResultTypeFilter: {
      [ReviewResultType.COMPLIANCE]: true,
      [ReviewResultType.NON_COMPLIANCE]: true,
      [ReviewResultType.ON_SITE_VERIFICATION]: true,
      [ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION]: true,
      [ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPONENTS]: true,
      [ReviewResultType.NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO]:
        true,
      [ReviewResultType.NO_ENOUGH_DATA_MISSING_LOCATION_INFO]: true,
      [ReviewResultType.NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS]:
        true,
      [ReviewResultType.NO_ENOUGH_DATA_OTHER]: true,
      [ReviewResultType.DEEPER_REVIEW]: true,
      [ReviewResultType.NOT_APPLICABLE]: true,
    },
  },
});
