import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import { PlanPage, XYPoint } from "../../model/blueprintServer";
import { formatImageUrl } from "../../util/imageUtil";
import "../planReport/pageView.scss";
import { AppLogoLoader } from "../other/AppLogoLoader";
import { useCanvas } from "../canvas/useCanvas";
import { useRecoilValue } from "recoil";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";
import { CanvasAnnotation } from "../canvas/util/canvasAnnotationUtil";
import { useScrollDetection } from "../projects/hook/useScrollDetection";
import { FaDownLong, FaUpLong } from "react-icons/fa6";
import { useBrowserEventListenerEvent } from "../../hooks/useBrowserEventListenerEvent";
import { useFireBrowserEvent } from "../projects/planManagment/useFireBrowserEvent";
import { Tooltip } from "react-tooltip";
import {filteredSectionComplianceReport} from "../planReport/atom/filteredPlanReportSectionsAtom";
import {SectionComplianceBubble} from "./SectionComplianceBubble";

export const CLICK_ON_PLAN_ANNOTATION_EVENT = "click-on-plan-annotation-event";

interface PageViewProps {
  page: PlanPage;
  handleFinishLoadImage: () => void;
  isLoadingImage: boolean;
  canvasAnnotations: CanvasAnnotation[];
  setPageIndex: (page: number) => void;
}

export const PageViewAndCanvas = ({
  page,
  handleFinishLoadImage,
  isLoadingImage,
  canvasAnnotations,
  setPageIndex,
}: PageViewProps) => {
  const { adjustedContainerWidth, adjustedContainerHeight } =
    useRecoilValue(pageImageSizeAtom);
  const [scrollProgress, setScrollProgress] = useState<{
    progress: number;
    isUp: boolean;
  }>({ progress: 0, isUp: false });
  const clickOnPlanAnnotation = useFireBrowserEvent(
    CLICK_ON_PLAN_ANNOTATION_EVENT,
  );
  const [tooltipPosition, setTooltipPosition] = useState<XYPoint | null>(null);
  const sections = useRecoilValue(filteredSectionComplianceReport);

  const handlePageUp = useCallback(() => {
    setPageIndex(page.pageNumber - 1);
  }, [setPageIndex, page.pageNumber]);

  const handlePageDown = useCallback(() => {
    setPageIndex(page.pageNumber + 1);
  }, [setPageIndex, page.pageNumber]);

  const handleClickOnAnnotation = (
    annotationKey: string | null,
    point: XYPoint | null,
  ) => {
    setTooltipPosition(point);
    if (annotationKey) {
      console.log(annotationKey)
      const annotation = canvasAnnotations.find(
        (c) => c.componentId === annotationKey,
      );
      if (!annotation) {
        return;
      }
      clickOnPlanAnnotation(annotation);
    }
  };
  const { Canvas, canvasRef } = useCanvas(
    page.pageImageBlob,
    page.pageNumber,
    canvasAnnotations,
    () => {},
    handleClickOnAnnotation,
  );
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp") {
      handlePageUp();
    } else if (event.key === "ArrowDown") {
      handlePageDown();
    }
  };

  useBrowserEventListenerEvent("keydown", handleKeyDown);

  const handleScrollProgress = (progress: number, isUp: boolean) =>
    setScrollProgress({ progress, isUp });

  useScrollDetection(
    canvasRef,
    handlePageUp,
    handlePageDown,
    handleScrollProgress,
    200,
  );

  const imageLoader = useMemo(
    () =>
      isLoadingImage && (
        <div
          className="loader"
          style={{
            width: adjustedContainerWidth,
            height: adjustedContainerHeight,
          }}
        >
          <AppLogoLoader />
        </div>
      ),
    [isLoadingImage, adjustedContainerHeight, adjustedContainerWidth],
  );
  
  const pageUpStyle = useMemo(
    () =>
      ({
        opacity: scrollProgress.isUp ? scrollProgress.progress : 0,
      }) as CSSProperties,
    [scrollProgress],
  );
  
  const pageDownStyle = useMemo(
    () =>
      ({
        opacity: !scrollProgress.isUp ? scrollProgress.progress : 0,
      }) as CSSProperties,
    [scrollProgress],
  );

  
  return (
    <div className="page-view-and-canvas">
      <Tooltip
        id="select-section-tooltip"
        isOpen={!!tooltipPosition}
        closeOnEsc={true}
        position={tooltipPosition || { x: 0, y: 0 }}
      >
        <SectionComplianceBubble />
      </Tooltip>
      <div className="canvas-wrapper">
        <div className="canvas">
          <div className="page-up-indicator" style={pageUpStyle}>
            <FaUpLong /> Previous page
          </div>
          <div className="page-down-indicator" style={pageDownStyle}>
            <FaDownLong /> Next page
          </div>
          {imageLoader}
          <img
            className="under-image"
            src={formatImageUrl(page.pageImageBlob)}
            onLoad={handleFinishLoadImage}
            style={{
              width: adjustedContainerWidth,
              height: adjustedContainerHeight,
            }}
          />
          {Canvas}
        </div>
      </div>
    </div>
  );
};
