import { Dropdown } from "../../other/dropdown/Dropdown";
import { useActivePlan } from "../../projects/hook/useActivePlan";
import { useMemo } from "react";
import { assertDefined } from "../../../util/assertions";
import { planReportFiltersAtom } from "../atom/PlanReportFiltersAtom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

export const PageNumberFilter = () => {
  const { getDefinedActivePlan } = useActivePlan();
  const activePlan = getDefinedActivePlan();
  const [{ pageIndex }, setPlanReportFilters] = useRecoilState(
    planReportFiltersAtom,
  );

  const handleChange = (pageIndex: number) => {
    setPlanReportFilters((old) => ({ ...old, pageIndex }));
  };

  const pages = useMemo(
    () => activePlan.pages.map((_, index) => index),
    [activePlan?.pages],
  );
  return (
    <Dropdown<number>
      values={pages}
      value={pageIndex}
      onChange={handleChange}
      valueFn={(i) => `Page ${assertDefined(i) + 1}`}
    />
  );
};
