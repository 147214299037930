import React, { useRef, useState } from "react";
import { FaAngleDown, FaCheck, FaFilter } from "react-icons/fa";
import { useHideOnClickOutside } from "../../other/dropdown/useHideOnClickOutside";
import { Tooltip } from "react-tooltip";
import { useClickOnDropdown } from "../../other/dropdown/useClickOnDropdown";
import { ReviewResultType } from "../../../model/blueprintServer";
import { getReportResultClass, getReportResultText } from "../reportUtil";
import "./finalAssessmentFilter.scss";
import classNames from "classnames";
import { useRecoilState } from "recoil";
import { planReportFiltersAtom } from "../atom/PlanReportFiltersAtom";

const TOOLTIP_KEY = "FinalAssessmentFilter";

export type ReviewResultTypeCategories = Record<ReviewResultType, boolean>;

const allMissingData = [
  ReviewResultType.NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS,
  ReviewResultType.NO_ENOUGH_DATA_MISSING_LOCATION_INFO,
  ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION,
  ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPONENTS,
  ReviewResultType.NO_ENOUGH_DATA_OTHER,
  ReviewResultType.NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO,
];

export const ReviewResultTypeFilter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const handleClick = useClickOnDropdown(ref, setIsOpen);
  useHideOnClickOutside(ref, setIsOpen);
  const [{ reviewResultTypeFilter }, setPlanReportFilters] = useRecoilState(
    planReportFiltersAtom,
  );

  const handleUpdate = (
    type: ReviewResultType,
    otherTypes: ReviewResultType[] = [],
  ) => {
    const state = !reviewResultTypeFilter[type];
    [type, ...otherTypes].forEach((value) =>
      setPlanReportFilters((old) => ({
        ...old,
        reviewResultTypeFilter: {
          ...old.reviewResultTypeFilter,
          [value]: state,
        },
      })),
    );
  };

  const renderItem = (
    value: ReviewResultType,
    otherValues: ReviewResultType[] = [],
    displayText: string | null = null,
  ) => (
    <div className="item flex" onClick={() => handleUpdate(value, otherValues)}>
      <div
        className={classNames("checkbox", {
          checked: reviewResultTypeFilter[value],
        })}
      >
        <FaCheck />
      </div>
      <div className={classNames("value", getReportResultClass(value))}>
        {displayText ?? getReportResultText(value)}
      </div>
    </div>
  );

  const icon = <FaFilter />;

  return (
    <div
      className="dropdown final-assessment-filter"
      ref={ref}
      data-tooltip-id={TOOLTIP_KEY}
      onClick={handleClick}
    >
      <span className="value-container">
        <div className="value flex">
          {icon}
          <span>Type</span>
        </div>
        <FaAngleDown />
      </span>
      <Tooltip id={TOOLTIP_KEY} place="bottom-start" clickable isOpen={isOpen}>
        <div className="dropdown-content">
          <div className="inner">
            <div className="list">
              {renderItem(ReviewResultType.COMPLIANCE)}
              {renderItem(ReviewResultType.NON_COMPLIANCE)}
              {renderItem(
                ReviewResultType.NO_ENOUGH_DATA_OTHER,
                allMissingData,
                "Missing data",
              )}
              {renderItem(ReviewResultType.DEEPER_REVIEW)}
              {renderItem(ReviewResultType.NOT_APPLICABLE)}
              {renderItem(ReviewResultType.ON_SITE_VERIFICATION)}
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
