import { Rect, XYPoint } from "../../../model/blueprintServer";

import { CanvasAnnotation } from "./canvasAnnotationUtil";

export const findFirstRectForPoint = (
  annotations: CanvasAnnotation[],
  point: XYPoint,
  pageNumber: number,
) => {
  const filteredAnnotations = annotations.filter(
    (annotation) => annotation.pageNumber === pageNumber,
  );
  const { x, y } = point;
  let index = 0;
  for (const annotation of filteredAnnotations) {
    const { rect } = annotation;

    if (
      x >= rect.start.x &&
      x <= rect.end.x &&
      y >= rect.start.y &&
      y <= rect.end.y
    ) {
      return { key: annotation.componentId, index, annotation };
    }
    index++;
  }

  return null;
};
export const scaleUpRect = (rect: Rect, scale: number): Rect => {
  return {
    start: scaleUpPoint(rect.start, scale),
    end: scaleUpPoint(rect.end, scale),
  };
};
export const scaleUpPoint = (point: XYPoint, scale: number): XYPoint => {
  const invertScale = 1 / scale;
  return { x: point.x * invertScale, y: point.y * invertScale };
};
