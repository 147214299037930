import { useEffect, RefObject, useState } from "react";

type ScrollHandler = () => void;
type ScrollProgressHandler = (progress: number, isUp: boolean) => void;

export const useScrollDetection = (
  ref: RefObject<HTMLElement>,
  handleScrollUp: ScrollHandler,
  handleScrollDown: ScrollHandler,
  scrollProgress: ScrollProgressHandler,
  scrollThreshold: number,
) => {
  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const [cumulativeDeltaY, setCumulativeDeltaY] = useState<number>(0);
  const [wheelEndTimeout, setWheelEndTimeout] =
    useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const handleWheelEnd = () => {
      setTouchStartY(null);
      setCumulativeDeltaY(0);
      const isUp = cumulativeDeltaY < 0;
      if (Math.abs(cumulativeDeltaY) >= scrollThreshold) {
        if (isUp) {
          handleScrollUp();
        } else {
          handleScrollDown();
        }
      }
      scrollProgress(0, isUp);
    };

    const onWheel = (e: WheelEvent) => {
      clearTimeout(wheelEndTimeout);
      const timeout = setTimeout(() => handleWheelEnd(), 200);
      setWheelEndTimeout(timeout);

      if (touchStartY === null) {
        setTouchStartY(e.clientY);
        return;
      }
      const delta = e.deltaY;
      const newDelta = cumulativeDeltaY + delta;
      setCumulativeDeltaY(newDelta);
      const isUp = delta < 0;
      const progress = Math.min(Math.abs(newDelta / scrollThreshold), 1);
      scrollProgress(progress, isUp);
    };

    element.addEventListener("wheel", onWheel, { passive: true });

    return () => {
      element.removeEventListener("wheel", onWheel);
    };
  }, [
    ref,
    handleScrollUp,
    handleScrollDown,
    scrollProgress,
    scrollThreshold,
    touchStartY,
    cumulativeDeltaY,
    wheelEndTimeout,
  ]);
};
