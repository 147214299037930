import React, { useState } from "react";
import { CityName } from "../../model/blueprintServer";
import { Dropdown } from "../other/dropdown/Dropdown";
import { planReportApi } from "../../api/planReportApi";
import { useNavigate } from "react-router-dom";

export const AdminCreateReportUI = () => {
  const [planId, setPlanId] = useState("");
  const [cityName, setCityName] = useState<CityName>(CityName.NEW_YORK_CITY);
  const [presetId, setPresetId] = useState<string>("");
  const navigate = useNavigate();

  const handleRun = async () => {
    const planReport = await planReportApi.create(planId, cityName, presetId);
    navigate("/");
  };

  return (
    <div>
      <h2>Create new report</h2>
      <div>
        <input
          value={planId}
          onChange={(e) => setPlanId(e.target.value)}
          placeholder="PlanId"
        />
        <input
          value={presetId}
          onChange={(e) => setPresetId(e.target.value)}
          placeholder="preset id"
        />
        <Dropdown
          value={cityName}
          values={[
            CityName.NEW_YORK_CITY,
            CityName.PHILADELPHIA,
            CityName.NEW_JERSEY,
          ]}
          onChange={setCityName}
        />
        <button onClick={handleRun}>Run</button>
      </div>
    </div>
  );
};
