import React, { useEffect, useState } from "react";
import { PlanQuestion } from "../../../model/blueprintServer";
import { SideBySideView } from "../../sideBySide/SideBySideView";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { SideBySideContentForPlanQa } from "../../sideBySide/SideBySideContentForPlanQa";
import { appMenuStateAtom } from "../../../atom/MenuStateAtom";
import { usePlanQaQuestions } from "../../../atom/questionAtom";

export const PlanQaPage = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);
  const planQaQuestions = usePlanQaQuestions();

  const setAppMenuState = useSetRecoilState(appMenuStateAtom);
  const [collapseAppSidebar, setCollapseAppSidebar] = useState(false);

  useEffect(() => {
    if (!collapseAppSidebar) {
      setAppMenuState((old) => ({ ...old, collapse: true }));
      setCollapseAppSidebar(true);
    }
  }, [collapseAppSidebar, setAppMenuState, setCollapseAppSidebar]);

  if (!currentProject.model || planQaQuestions.isNotDone()) {
    return null;
  }

  return (
    <div>
      <SideBySideView<PlanQuestion>
        items={planQaQuestions.data()}
        cityName={currentProject.model.jurisdiction.cityName}
        RightHandSide={SideBySideContentForPlanQa}
        title="Q&A"
      />
    </div>
  );
};
