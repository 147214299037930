import { PagesIndex } from "../../planReport/PagesIndex";
import { PageViewAndCanvas } from "../../sideBySide/PageViewAndCanvas";
import React, { useEffect, useMemo, useState } from "react";
import { useActivePlan } from "../hook/useActivePlan";
import "./planViewer.scss";
import { useHighlightedPageAndComponentListener } from "./useHighlightedPageAndComponentListener";
import { planApi } from "../../../api/planApi";
import { PlanModel } from "../../../model/blueprintServer";

import {
  CanvasAnnotation,
  componentToCanvasAnnotation,
  getTheme,
} from "../../canvas/util/canvasAnnotationUtil";
import { useRecoilState, useRecoilValue } from "recoil";
import { planReportFiltersAtom } from "../../planReport/atom/PlanReportFiltersAtom";
import { filteredSectionComplianceReport } from "../../planReport/atom/filteredPlanReportSectionsAtom";

export const PlanViewer = () => {
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const { activePlan } = useActivePlan();
  const [fullPlan, setFullPlan] = useState<PlanModel | null>(null); // Add state for fullPlan
  const [highlightedComponentsIds, setHighlightedComponentsIds] = useState<
    string[]
  >([]);
  const [{ pageIndex }, setPlanReportFilters] = useRecoilState(
    planReportFiltersAtom,
  );
  const filteredSections = useRecoilValue(filteredSectionComplianceReport);

  useEffect(() => {
    if (activePlan) {
      planApi.getFullPlan(activePlan.id).then(setFullPlan);
    }
  }, [activePlan]);

  const setPageIndex = (newPageIndex: number) => {
    if (!activePlan) {
      return;
    }

    if (
      newPageIndex > -1 &&
      newPageIndex < activePlan.pages.length &&
      newPageIndex !== pageIndex
    ) {
      setPlanReportFilters((old) => ({ ...old, pageIndex: newPageIndex }));
      setIsLoadingImage(true);
    }
  };
  useHighlightedPageAndComponentListener(
    setPageIndex,
    setHighlightedComponentsIds,
  );

  const pageComponents = useMemo((): CanvasAnnotation[] => {
    if (!fullPlan) {
      return [];
    }

    const page = fullPlan.pages[pageIndex];
    const acc: CanvasAnnotation[] = [];
    filteredSections.flat().forEach((section) => {
      section.relevantComponents.forEach((ref) => {
        const components = page.components.find(
          (c) => c.componentId === ref.componentId,
        );
        if (components) {
          acc.push(
            componentToCanvasAnnotation(
              components,
              pageIndex,
              getTheme(section.reviewResultType),
            ),
          );
        }
      });
    });
    return acc;
  }, [fullPlan, pageIndex, filteredSections]);

  if (!activePlan) {
    return null;
  }

  return (
    <div className="plan-viewer flex">
      <PagesIndex setPageIndex={setPageIndex} currentPageIndex={pageIndex} />
      <PageViewAndCanvas
        setPageIndex={setPageIndex}
        page={activePlan.pages[pageIndex]}
        canvasAnnotations={pageComponents}
        handleFinishLoadImage={() => setIsLoadingImage(false)}
        isLoadingImage={isLoadingImage}
      />
    </div>
  );
};
