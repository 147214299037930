import React, { useMemo } from "react";
import { ReviewResultType } from "../../../model/blueprintServer";
import { SectionComplianceReportCard } from "../SectionComplianceReportCard";
import { getReportResultText } from "../reportUtil";
import { ReportResultFilter } from "../reportSectionFilter/ReportResultFilter";
import "./reportListJourney.scss";
import { useRecoilValue } from "recoil";
import { planReportFiltersAtom } from "../atom/PlanReportFiltersAtom";
import {
  filteredSectionComplianceReport,
  groupedFilteredSectionComplianceReport,
} from "../atom/filteredPlanReportSectionsAtom";

export const ReportListJourney = () => {
  const { reviewResultTypeFilter } = useRecoilValue(planReportFiltersAtom);
  const groupedSections = useRecoilValue(
    groupedFilteredSectionComplianceReport,
  );
  const sections = useRecoilValue(filteredSectionComplianceReport);

  const allSections = useMemo(() => {
    return Object.entries(groupedSections)
      .filter(
        ([category, sections]) =>
          reviewResultTypeFilter[category as ReviewResultType],
      )
      .filter(([_, items]) => items.length)
      .map(([key, items]) => {
        const cards = items.map((item, key) => (
          <SectionComplianceReportCard item={item} key={key} />
        ));
        return (
          <div className="group" key={key}>
            <div className="header flex">
              {getReportResultText(key)}
              <span>({items.length})</span>
            </div>
            <div className="section flex">{cards}</div>
          </div>
        );
      });
  }, [groupedSections, reviewResultTypeFilter]);

  const emptyMessage = useMemo(() => {
    if (sections.length === 0) {
      return <div className="empty flex">No issue in this page</div>;
    }
  }, [sections]);

  return (
    <div className="report-list-journey flex">
      <ReportResultFilter />
      <div className="results flex">
        {emptyMessage}
        {allSections}
      </div>
    </div>
  );
};
