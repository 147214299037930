import { useEffect } from "react";

export const useBrowserEventListenerEvent = (
  eventName: string,
  handler: (event: any) => void,
  option: boolean = false,
) => {
  useEffect(() => {
    window.addEventListener(eventName, handler);

    return () => {
      window.removeEventListener(eventName, handler);
    };
  }, [eventName, handler]);
};
