import { BaseApi } from "./BaseApi";
import {
  AskQuestionRequest,
  Jurisdiction,
  PlanQuestion,
} from "../model/blueprintServer";

class QuestionApi extends BaseApi {
  async ask(
    planQaId: string,
    question: string,
    jurisdiction: Jurisdiction,
  ): Promise<PlanQuestion> {
    const body: AskQuestionRequest = {
      jurisdiction,
      question,
      planQaId,
    };
    return this.post(`ask`, body, {}, 60 * 1000);
  }

  async getAll(planQaId: string): Promise<PlanQuestion[]> {
    return this.get(`all?planQaId=${planQaId}`);
  }

  async getQuestion(planQuestionId: string): Promise<PlanQuestion> {
    return this.get(`get?planQuestionId=${planQuestionId}`);
  }
}

export const questionApi = new QuestionApi("plan-qa/question");
