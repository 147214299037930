import React from "react";
import { AdminCreateReportUI } from "./AdminCreateReportUI";
import { AdminRerunReport } from "./AdminRerunReport";

export const AdminReportUI = () => {
  return (
    <>
      <AdminCreateReportUI />
      <AdminRerunReport />
    </>
  );
};
