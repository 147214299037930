import React, { useEffect, useState } from "react";
import { useCurrentPlanReportData } from "../../atom/currentPlanReportAtom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AppLogoLoader } from "../other/AppLogoLoader";
import { assertDefined } from "../../util/assertions";
import {
  DataLoadingStates,
  SectionComplianceReport,
} from "../../model/blueprintServer";
import { SideBySideView } from "../sideBySide/SideBySideView";
import { currentProjectsAtom } from "../../atom/currentProjectAtom";
import "./planReportPage.scss";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { appMenuStateAtom } from "../../atom/MenuStateAtom";
import { SideBySideContentForReport } from "../sideBySide/SideBySideContentForReport";
import { filteredSectionComplianceReport } from "./atom/filteredPlanReportSectionsAtom";

export const PlanReportPage = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);
  const planReportData = useCurrentPlanReportData();
  const { activePlan } = useActivePlan();
  const setAppMenuState = useSetRecoilState(appMenuStateAtom);
  const [collapseAppSidebar, setCollapseAppSidebar] = useState(false);
  const sections = useRecoilValue(filteredSectionComplianceReport);

  useEffect(() => {
    if (!collapseAppSidebar) {
      setAppMenuState((old) => ({ ...old, collapse: true }));
      setCollapseAppSidebar(true);
    }
  }, [collapseAppSidebar, setAppMenuState, setCollapseAppSidebar]);

  if (planReportData.isNotDone() || !activePlan) {
    return <AppLogoLoader />;
  }

  if (planReportData.data().status === DataLoadingStates.ERROR) {
    return (
      <div className="plan-report-error">
        We experienced an error while generating this report
      </div>
    );
  }

  if (planReportData.data().status !== DataLoadingStates.COMPLETED) {
    return <div>Creating report, we will email you when it is ready</div>;
  }

  const project = assertDefined(currentProject.model);
  return (
    <div className="plan-report-page">
      <SideBySideView<SectionComplianceReport>
        items={sections}
        cityName={project.jurisdiction.cityName}
        RightHandSide={SideBySideContentForReport}
        title={planReportData.data().title}
        additionalTitle={`Created ${new Date(
          planReportData.data().created,
        ).toDateString()}`}
      />
    </div>
  );
};
