import { BaseApi } from "./BaseApi";
import { OktaUserInfo } from "../model/blueprintServer";

export interface OktaOrgMember {
  user_id: string;
  email: string;
  picture: string;
  name: string;
}

class OrganizationApi extends BaseApi {
  async members(): Promise<OktaOrgMember[]> {
    return await this.get("members");
  }

  async memberForResource(resourceId: string): Promise<OktaOrgMember> {
    return await this.get(`resource-creator?resourceId=${resourceId}`);
  }
}

export const organizationApi = new OrganizationApi("organization");
