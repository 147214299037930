import React, { useMemo, useRef } from "react";
import { marked } from "marked";
import { BuildingCodeCitation, detectSpecialLinks } from "./parseCitationUtil";
import parse, { DOMNode, Element } from "html-react-parser";
import { useFetchBuildingCode } from "../projects/hook/useFetchBuildingCode";
import { BuildingCodeRefLink } from "./BuildingCodeRefLink";
import { PageRefLink } from "./PageRefLink";
import { assertDefined } from "../../util/assertions";
import { DATA_ATTRIBUTES, DATA_EL_TYPE } from "./dataAttributes";
import { CityNameDisplay } from "./CityNameDisplay";

const getDataRef = (el: Element, dataAtt: string) =>
  el.attributes.find((a) => a.name === dataAtt)?.value;

interface MountHtmlProps {
  markdown?: string;
}

const hasDataCitation = (element: Element) =>
  !!element.attributes.find((a) => a.name === DATA_ATTRIBUTES.TYPE);

const replaceFunction = (domNode: DOMNode) => {
  const el = domNode as Element;
  if (!el || !el.attribs || el.tagName !== "span") {
    return domNode;
  }

  const type = getDataRef(el, DATA_ATTRIBUTES.TYPE);
  if (!type) {
    return domNode;
  }

  const page = getDataRef(el, DATA_ATTRIBUTES.PAGE);
  if (type === DATA_EL_TYPE.BUILDING_CODE) {
    const buildingCode = getDataRef(el, DATA_ATTRIBUTES.BUILDING_CODE);
    const citation = JSON.parse(
      assertDefined(buildingCode),
    ) as BuildingCodeCitation;
    return <BuildingCodeRefLink citation={citation} text={citation.section} />;
  } else if (type === DATA_EL_TYPE.PAGE_CITATION) {
    return (
      <PageRefLink
        page={assertDefined(page)}
        text={`page ${page}`}
        tag={page + ""}
      />
    );
  } else if (type === DATA_EL_TYPE.COMPONENT_CITATION) {
    const componentId = getDataRef(el, DATA_ATTRIBUTES.COMPONENT_ID);
    const componentTitle = getDataRef(el, DATA_ATTRIBUTES.COMPONENT_TITLE);
    return (
      <PageRefLink
        page={assertDefined(page)}
        text={assertDefined(componentTitle)}
        componentId={assertDefined(componentId)}
        tag={componentId}
      />
    );
  } else if (type === DATA_EL_TYPE.CITY_NAME) {
    const name = getDataRef(el, DATA_ATTRIBUTES.CITY_NAME);
    return <CityNameDisplay name={name} />;
  } else if (hasDataCitation(el)) {
    return null;
  }
  return domNode;
};

export const MarkdownCitation = ({ markdown = "" }: MountHtmlProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const fetchBuildingCode = useFetchBuildingCode();

  const content = useMemo(() => {
    const { text, citations } = detectSpecialLinks(markdown);
    // TODO: send with each section the city and year. Now we assume they are from the same city+year.
    if (citations.length !== 0) {
      const cityName = citations[0].cityName;
      const year = citations[0].year;
      const sections = citations.map((c) => c.section);
      fetchBuildingCode(cityName, year, sections);
    }

    if (!text) {
      return null;
    }

    const markdownHtml = marked.parse(text) as string;
    return parse(markdownHtml, {
      replace: replaceFunction,
    });
  }, [markdown, fetchBuildingCode]);

  return <div ref={ref}>{content}</div>;
};
