import React, { useRef, useState } from "react";
import { MarkdownCitation } from "../markdown/MarkdownCitation";
import classNames from "classnames";
import { getReportResultClass } from "./reportUtil";
import { SectionComplianceReport } from "../../model/blueprintServer";
import { codeSymbol } from "../../util/codeFormatUtil";
import { Button, ButtonColor } from "../theme/Button";
import { useBrowserEventListenerEvent } from "../../hooks/useBrowserEventListenerEvent";
import { CLICK_ON_PLAN_ANNOTATION_EVENT } from "../sideBySide/PageViewAndCanvas";
import { CanvasAnnotation } from "../canvas/util/canvasAnnotationUtil";

interface SectionCardProps {
  item: SectionComplianceReport;
}

export const SectionComplianceReportCard = ({ item }: SectionCardProps) => {
  const [expandedCard, setExpandedCard] = useState(false);
  const [expandCode, setExpendCode] = useState(false);
  const [expandedReasons, setExpandedReasons] = useState<
    Record<number, boolean>
  >({});
  const [isSelected, setSelected] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useBrowserEventListenerEvent(CLICK_ON_PLAN_ANNOTATION_EVENT, ({ detail }) => {
    const annotation = detail as CanvasAnnotation;
    const { componentId, pageNumber } = annotation;
    const findComponentInSection = item.relevantComponents.find(
      (c) => c.componentId === componentId,
    );
    if (findComponentInSection) {
      setExpandedCard(true);
      setExpandedReasons((old) => ({ ...old, 0: true }));
      setSelected(true);
      ref?.current?.scrollIntoView({ behavior: "smooth" });
    }
  });
  const toggleReason = (index: number) => {
    setExpandedReasons((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div
      className={classNames("card", {
        expanded: expandedCard,
        selected: isSelected,
      })}
      onMouseLeave={() => setSelected(false)}
      ref={ref}
    >
      <div
        className="flex card-header"
        onClick={() => setExpandedCard((old) => !old)}
      >
        <div
          className={classNames(
            "title",
            getReportResultClass(item.reviewResultType),
          )}
        >
          {codeSymbol(item.buildingCode)} {item.buildingCode.fullSectionPath} @{" "}
          {item.buildingCode.year}
        </div>
        <div className="chapter-title">{item.buildingCode.title}</div>
      </div>
      {expandedCard && (
        <div className="content">
          {!expandCode && (
            <div
              className="show-code-hover"
              onClick={() => setExpendCode((old) => !old)}
            >
              Show code
            </div>
          )}
          {expandCode && (
            <div>
              <div className="sub-title flex">
                {expandCode && <span>Code ({item.buildingCode.year})</span>}
                {expandCode && (
                  <Button
                    color={ButtonColor.TRANSPARENT}
                    onClick={() => setExpendCode(false)}
                  >
                    Hide
                  </Button>
                )}
              </div>
              <div className={classNames({ expend: expandCode })}>
                {expandCode && (
                  <div className="code">
                    <MarkdownCitation markdown={item.buildingCode.text} />
                  </div>
                )}
              </div>
            </div>
          )}

          {item.reasoning.map((reason, index) => (
            <div className="reason" key={index}>
              <h4 onClick={() => toggleReason(index)}>
                Reason {index + 1}
                <span className="analysis-compliance show">
                  {expandedReasons[index] ? "hide" : "show"}
                </span>
              </h4>
              {expandedReasons[index] && (
                <div>
                  <div className="sub-title">Analysis</div>
                  <MarkdownCitation markdown={reason.analysis} />
                  <div className="sub-title">Evaluation</div>
                  <MarkdownCitation markdown={reason.evaluation} />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
