import { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  planQaQuestionsAtom,
  usePlanQaQuestions,
} from "../../../../atom/questionAtom";
import { useFetchBuildingCode } from "../../hook/useFetchBuildingCode";
import { questionApi } from "../../../../api/questionApi";
import { assertDefined } from "../../../../util/assertions";
import { currentProjectsAtom } from "../../../../atom/currentProjectAtom";
import { useParams } from "react-router-dom";

export const useLoadPlanQaQuestions = () => {
  const fetchCode = useFetchBuildingCode();
  const currentProject = useRecoilValue(currentProjectsAtom);
  const planQaQuestions = usePlanQaQuestions();
  const resetQuestions = useResetRecoilState(planQaQuestionsAtom);
  const { planQaId } = useParams();
  const [currentPlanQaId, setCurrentPlanQaId] = useState("");

  useEffect(() => {
    if (
      !planQaId ||
      currentPlanQaId === planQaId ||
      planQaQuestions.isLoading()
    ) {
      return;
    }

    setCurrentPlanQaId(planQaId);
    resetQuestions();
    planQaQuestions.startLoading();
    questionApi.getAll(planQaId).then((data) => {
      planQaQuestions.update(data);
      planQaQuestions.endLoading();
      const codes = data.reduce(
        (prev, current) => [
          ...prev,
          ...(current.answer?.relevantSections ?? []).map((s) => s.section),
        ],
        [] as string[],
      );

      const project = assertDefined(currentProject.model);
      fetchCode(
        project.jurisdiction.cityName,
        project.jurisdiction.codeYear,
        codes,
      );
    });
  }, [
    planQaQuestions,
    currentPlanQaId,
    setCurrentPlanQaId,
    planQaId,
    currentProject.model,
    fetchCode,
    resetQuestions,
  ]);
};
