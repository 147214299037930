import { PlanQuestion } from "../model/blueprintServer";
import { createDataAtom, useData } from "./AtomDataClass";

export const planQaQuestionsAtom = createDataAtom<PlanQuestion[]>(
  "planQaQuestions",
  [],
);

export const usePlanQaQuestions = () =>
  useData<PlanQuestion[]>(planQaQuestionsAtom);
