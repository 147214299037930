import { useEffect, useState } from "react";
import { usePlanQaListData } from "../../../../atom/planQaAtom";
import { planQaApi } from "../../../../api/PlanQaApi";
import { useActivePlan } from "../../hook/useActivePlan";

export const useLoadPlanQaList = () => {
  const planQaList = usePlanQaListData();
  const { activePlan } = useActivePlan();
  const [currentActivePlan, setCurrentActivePlan] = useState("");

  useEffect(() => {
    if (!activePlan) {
      return;
    }

    if (currentActivePlan === activePlan?.id || planQaList.isLoading()) {
      return;
    }

    setCurrentActivePlan(activePlan.id);
    planQaList.startLoading();
    planQaApi.getAll(activePlan.id).then((data) => {
      planQaList.update(data);
      planQaList.endLoading();
    });
  }, [planQaList, activePlan, currentActivePlan, setCurrentActivePlan]);
};
