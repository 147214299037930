export type Painter = (ctx: CanvasRenderingContext2D) => void;

export const runPainter = (ctx: CanvasRenderingContext2D, painter: Painter) => {
  try {
    painter(ctx);
  } catch (e) {
    console.error("Failed to paint");
    console.log(e);
  }
};
