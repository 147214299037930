import React from "react";
import { useRecoilValue } from "recoil";
import { serverAuthFinishedAtom } from "../../atom/serverAuthFinishedAtom";
import "./debugger.scss";
import { FaQuestion } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { useParams } from "react-router-dom";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { Button, ButtonColor } from "../theme/Button";
import { FaCopy } from "react-icons/fa6";
import { APP_VERSION } from "../app/App";

const DEBUGGER_ROLE = "debugger-blueplan";

export const Debugger = () => {
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);
  if (!appLevelUser?.roles.includes(DEBUGGER_ROLE)) {
    return null;
  }

  return <DebuggerInner />;
};

const DebuggerInner = () => {
  return (
    <div className="debugger" data-tooltip-id="debugger">
      <DebuggerContent />
      <div className="placeholder">
        <FaQuestion />
      </div>
    </div>
  );
};
const keyValue = (key: string, value: string | undefined) => {
  if (!value) {
    return null;
  }

  return (
    <div className="item">
      <div className="key">{key}</div>
      <div className="value">
        <div>{value}</div>
        <Button
          color={ButtonColor.ICON}
          IconLeft={FaCopy}
          onClick={() => navigator.clipboard.writeText(value)}
        />
      </div>
    </div>
  );
};

const DebuggerContent = () => {
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);
  const { projectId, planQaId, reportId } = useParams();
  const { activePlan } = useActivePlan();
  return (
    <Tooltip id="debugger" openOnClick clickable>
      <div>
        {keyValue("Project Id", projectId)}
        {keyValue("Plan Id", activePlan?.id)}
        {keyValue("Plan QA Id", planQaId)}
        {keyValue("Report Id", reportId)}
        {keyValue("User id", appLevelUser?.oktaUserId)}
        {keyValue("Roles", appLevelUser?.roles.join(", "))}
        {keyValue("Version", APP_VERSION)}
      </div>
    </Tooltip>
  );
};
