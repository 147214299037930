import { useMemberForResource } from "../../hooks/useMemberForResource";
import "./resourceAvatar.scss";
import { Tooltip } from "react-tooltip";

interface ResourceAvatar {
  resourceId: string;
}
export const ResourceAvatar = ({ resourceId }: ResourceAvatar) => {
  const member = useMemberForResource(resourceId);
  return (
    <div className="resource-avatar" data-tooltip-id={resourceId}>
      <img src={member.picture} />
      <Tooltip id={resourceId}>{member.name}</Tooltip>
    </div>
  );
};
