import React, { useCallback, useEffect, useRef } from "react";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { formatImageUrl } from "../../util/imageUtil";
import "./pagesIndex.scss";
import classNames from "classnames";
import { useFireBrowserEvent } from "../projects/planManagment/useFireBrowserEvent";
import { RESET_COMPONENT_IN_PAGE_INDEX_EVENT } from "../markdown/PageRefLink";
import { planReportFiltersAtom } from "./atom/PlanReportFiltersAtom";
import { useRecoilValue } from "recoil";

interface PagesIndexProps {
  setPageIndex: (page: number) => void;
  currentPageIndex: number;
}

export const PagesIndex = ({ setPageIndex }: PagesIndexProps) => {
  const { activePlan } = useActivePlan();
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const resetSelectedComponent = useFireBrowserEvent(
    RESET_COMPONENT_IN_PAGE_INDEX_EVENT,
  );
  const { pageIndex } = useRecoilValue(planReportFiltersAtom);

  useEffect(() => {
    const currentRef = pageRefs.current[pageIndex];
    if (currentRef) {
      currentRef.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [pageIndex]);

  const handleClick = useCallback(
    (pageNumber: number) => {
      resetSelectedComponent();
      setPageIndex(pageNumber);
    },
    [setPageIndex, resetSelectedComponent],
  );

  if (!activePlan) {
    return null;
  }

  const pages = activePlan.pages.map((page, index) => (
    <div
      key={page.pageNumber}
      className={classNames("item flex", {
        selected: pageIndex === page.pageNumber,
      })}
      ref={(el) => (pageRefs.current[index] = el)}
    >
      <img
        src={formatImageUrl(page.thumbnailBlob)}
        onClick={() => handleClick(page.pageNumber)}
        key={page.id}
      />
      <span>{page.pageNumber + 1}</span>
    </div>
  ));
  return <div className="pages-index flex">{pages}</div>;
};
