import { useState, useCallback } from "react";

function useIsLoad() {
  const [loadingStatuses, setLoadingStatuses] = useState<
    Record<string, boolean>
  >({});

  const isLoading = useCallback(
    (id: string): boolean => loadingStatuses[id],
    [loadingStatuses],
  );

  const setLoadStatus = useCallback(
    (id: string, status: boolean): void => {
      setLoadingStatuses((prevStatuses) => ({
        ...prevStatuses,
        [id]: status,
      }));
    },
    [setLoadingStatuses],
  );

  return { isLoading, setLoadStatus };
}

export default useIsLoad;
