import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Painter, runPainter } from "./painter/painterUtil";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";
import { useRecoilValue } from "recoil";
import { Size } from "../../model/math";
import { useDrag } from "./useDrag";
import {
  BlobStorage,
  PlanAnnotation,
  Rect,
  XYPoint,
} from "../../model/blueprintServer";
import { formatImageUrl } from "../../util/imageUtil";
import { throttle } from "lodash";
import { annotationForPainter } from "./overlayUtils";
import "./canvas.scss";
import {
  findFirstRectForPoint,
  scaleUpPoint,
  scaleUpRect,
} from "./util/canvasRectUtil";
import { getImageMeta } from "./util/canvasImageUtil";
import { CanvasAnnotation } from "./util/canvasAnnotationUtil";
import { reactForAnnotation } from "./painter/annotationPainter";
import { clearRectPainter } from "./painter/rectPainter";
import { useCanvasSizeAndScale } from "./useCanvasSizeAndScale";

export const useCanvas = (
  imageBlob: BlobStorage,
  pageNumber: number,
  annotations: CanvasAnnotation[],
  handleAddAnnotation: (pageNumber: number, rect: Rect) => void,
  handleSelectedAnnotationKey: (key: string | null, point: XYPoint | null) => void,
) => {
  const [size, setSize] = useState<Size | null>(null);
  const [currentRect, setCurrentRect] = useState<Rect | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { scale, originalImageWidth, originalImageHeight } =
    useRecoilValue(pageImageSizeAtom);
  const [currentHoverPoint, setCurrentHoverPoint] = useState<XYPoint>({
    x: -1,
    y: -1,
  });
  const [currentClickPoint, setCurrentClickPoint] = useState<XYPoint>({
    x: -1,
    y: -1,
  });
  const { scaleOverDpi, dpi } = useCanvasSizeAndScale(size, setSize, canvasRef);

  const handleRectMove = useCallback(
    (rect: Rect, isNewRect: boolean) => {
      setCurrentRect(!isNewRect ? scaleUpRect(rect, scaleOverDpi) : null);
      if (isNewRect) {
        handleAddAnnotation(pageNumber, scaleUpRect(rect, scale));
      }
    },
    [setCurrentRect, scale, pageNumber, handleAddAnnotation],
  );
  const { handleMouseDown } = useDrag(handleRectMove);

  const drawFrame = useCallback(
    (imageUrl: string, painters: Painter[]) => {
      let canvas = canvasRef.current;
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        return;
      }

      if (!size) {
        getImageMeta(imageUrl, (imgEle) => {
          setSize({ width: imgEle.naturalWidth, height: imgEle.naturalHeight });
        });
        return;
      }

      for (const painter of painters) {
        ctx.scale(scale, scale);
        runPainter(ctx, painter);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
      }
    },
    [canvasRef, scale, size, setSize],
  );

  const findRect = useCallback(
    (point: XYPoint) => {
      return findFirstRectForPoint(annotations, point, pageNumber);
    },
    [pageNumber, scale, annotations],
  );

  useEffect(() => {
    const handleMouseMove = throttle((e) => {
      const { offsetX, offsetY } = e;
      if (offsetY < 0 || offsetX < 0) {
        return;
      }
      // For optimization, set the hover rect here instead of the big useEffect and avoid re-render unless there is a
      // hovered rect.
      setCurrentHoverPoint(
        scaleUpPoint({ x: offsetX, y: offsetY }, scaleOverDpi),
      );
    }, 100);
    canvasRef.current?.addEventListener("mousemove", handleMouseMove);

    const ref = canvasRef.current;
    return () => {
      ref?.removeEventListener("mousemove", handleMouseMove);
    };
  }, [canvasRef, findRect]);

  useEffect(() => {
    const handleClick = throttle((e) => {
      const { offsetX, offsetY } = e;
      if (offsetY < 0 || offsetX < 0) {
        return;
      }

      const point = scaleUpPoint({ x: offsetX, y: offsetY }, scaleOverDpi);
      setCurrentClickPoint(point);
      const clickOnAnnotation = findRect(point);
      if (clickOnAnnotation) {
        handleSelectedAnnotationKey(clickOnAnnotation.key, { x: e.x, y: e.y });
      } else {
        handleSelectedAnnotationKey(null, null);
      }
    }, 100);
    canvasRef.current?.addEventListener("click", handleClick);

    const ref = canvasRef.current;
    return () => {
      ref?.removeEventListener("click", handleClick);
    };
  }, [canvasRef, findRect, handleSelectedAnnotationKey, currentClickPoint]);

  useEffect(() => {
    const painters = [
      clearRectPainter(originalImageWidth * dpi, originalImageHeight * dpi),
    ];

    if (currentRect) {
      const annotation: PlanAnnotation = {
        rect: currentRect,
        label: "",
        id: "",
        planQaId: "",
        pageNumber: pageNumber,
        image: { bucket: "", id: "", md5: "" },
        ready: true,
        failed: false,
      };
      painters.push(reactForAnnotation([annotationForPainter(annotation)]));
    }

    const hoverOnAnnotation = findRect(currentHoverPoint);
    let hoverRectIndex = -1;
    if (hoverOnAnnotation) {
      hoverRectIndex = hoverOnAnnotation.index;
    }

    const clickOnAnnotation = findRect(currentClickPoint);
    let clickRectIndex = -1;
    if (clickOnAnnotation) {
      clickRectIndex = clickOnAnnotation.index;
    }

    const rects = annotations
      .filter((a) => a.pageNumber === pageNumber)
      .map((canvasAnnotation, index) => ({
        ...canvasAnnotation,
        isHovered: hoverRectIndex === index,
        isSelected: clickRectIndex === index,
      }));

    painters.push(reactForAnnotation(rects));
    drawFrame(formatImageUrl(imageBlob), painters);
  }, [
    scale,
    drawFrame,
    originalImageHeight,
    originalImageWidth,
    currentRect,
    imageBlob,
    pageNumber,
    currentHoverPoint,
    currentClickPoint,
    findRect,
    annotations,
  ]);

  const Canvas = useMemo(
    () => (
      <canvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        id="canvas"
      ></canvas>
    ),
    [canvasRef, handleMouseDown],
  );
  return { Canvas, canvasRef, drawFrame };
};
