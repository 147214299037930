import { Address, CityName } from "../model/blueprintServer";

const addressString = (address: Address) => {
  const newYork = parseNewYorkAddress(address);
  if (newYork !== null) {
    return newYork;
  }

  const philadelphia = parsePhiladelphiaAddress(address);
  if (philadelphia) {
    return philadelphia;
  }

  throw new Error("Can't parse address " + address);
};

const parseNewYorkAddress = (address: Address) => {
  if (!address.newYorkDetails) {
    return null;
  }

  return `${address.number} ${address.street}, ${address.newYorkDetails.nyBorough}, ${address.state}`;
};

const parsePhiladelphiaAddress = (address: Address) => {
  if (address.philadelphiaDetails === null) {
    return null;
  }

  return `${address.number} ${address.street}, ${address.city ?? ""}, ${
    address.state
  }`;
};

export const addressToText = (address: Address) => (
  <span>{addressString(address)}</span>
);

export const getCityId = (address: Address) => {
  const nyc = address.newYorkDetails?.bin;
  if (nyc) {
    return `BIN: ${nyc}`;
  }

  // We don't have the phily city ID yet.
  return "";
};

export const getCityDisplayName = (city: CityName) => {
  switch (city) {
    case CityName.BRONX:
      return "New York City";
    case CityName.MANHATTAN:
      return "New York City";
    case CityName.BROOKLYN:
      return "New York City";
    case CityName.QUEENS:
      return "New York City";
    case CityName.STATEN_ISLAND:
      return "New York City";
    case CityName.NEW_YORK_CITY:
      return "New York City";
    case CityName.PHILADELPHIA:
      return "Philadelphia";
    case CityName.NEW_JERSEY:
      return "New Jersey";
    case CityName.PARAMUS:
      return "Paramus";
    default:
      throw new Error("Can't find city " + city);
  }
};
