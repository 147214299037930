import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router";
import { RecoilRoot } from "recoil";
import Modal from "react-modal";
import { apiInstance } from "../../api/apiInstance";
import "./antOverride.scss";
import "./app.scss";
import { Auth0Provider } from "@auth0/auth0-react";
import { ROUTER_PATHS } from "./routerPaths";
import VERSION from "../../VERSION.json";
import { appApi } from "../../api/appApi";
import { mouseflow } from "react-mouseflow";

Modal.setAppElement("#root");
apiInstance.create();

const App = () => {
  useEffect(() => {
    mouseflow.initialize("4bd111f4-df85-4575-80d7-1379ec31b4d7\n");
  }, []);

  return (
    <div className="app">
      <RecoilRoot>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
          authorizationParams={{
            redirect_uri: window.location.origin + "/" + ROUTER_PATHS.ROOT,
          }}
        >
          <RouterProvider router={router} />
        </Auth0Provider>
      </RecoilRoot>
    </div>
  );
};

export let APP_VERSION = "";
const validateMatchingVersions = async () => {
  APP_VERSION = await appApi.serverVersion();
  APP_VERSION = APP_VERSION.replace("version ", "");
  const matching = Number(APP_VERSION) <= Number(VERSION.current);
  console.info(`App = ${VERSION.current}\nserver = ${APP_VERSION}`);
  if (!matching) {
    console.error("Mismatching versions ");
  }
};

(async () => {
  validateMatchingVersions();
})();

// @ts-ignore
global.version = VERSION.current;

export default App;
