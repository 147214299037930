import {
  ReviewResultType,
  SectionComplianceReport,
} from "../../../model/blueprintServer";
import { PlanReportFiltersAtom } from "../atom/PlanReportFiltersAtom";
import { ReviewResultTypeCategories } from "../reportSectionFilter/ReviewResultTypeFilter";

export const sectionsComplianceReportFilter = (
  sections: SectionComplianceReport[],
  { reviewResultTypeFilter, searchText, pageIndex }: PlanReportFiltersAtom,
) =>
  sort([...sections]).filter(
    (section) =>
      filterBySearchText(section, searchText) &&
      filterByCategory(section, reviewResultTypeFilter) &&
      filterByPageIndex(section, pageIndex),
  );

const filterBySearchText = (
  item: SectionComplianceReport,
  searchText: string,
): boolean => {
  const inputLowerCase = searchText.toLowerCase();
  const inPath = item.buildingCode.fullSectionPath
    .toLowerCase()
    .includes(inputLowerCase);
  const inTitle = item.buildingCode.title
    .toLowerCase()
    .includes(inputLowerCase);
  return inTitle || inPath;
};

const filterByCategory = (
  section: SectionComplianceReport,
  categories: ReviewResultTypeCategories,
) => {
  const categoriesList = Object.entries(categories).reduce(
    (acc: string[], [category, isEnabled]) => {
      if (isEnabled) {
        acc = [...acc, category as string];
      }
      return acc;
    },
    [],
  );
  return categoriesList.includes(section.reviewResultType);
};

const filterByPageIndex = (
  section: SectionComplianceReport,
  pageIndex: number,
) =>
  section.relevantComponents.filter((item) => item.pageNumber === pageIndex)
    .length > 0;

const sort = (
  sections: SectionComplianceReport[],
): SectionComplianceReport[] => {
  return sections.sort((a, b) =>
    a.buildingCode.fullSectionPath.localeCompare(
      b.buildingCode.fullSectionPath,
    ),
  );
};

export const groupSections = (
  sections: SectionComplianceReport[],
): Record<ReviewResultType, SectionComplianceReport[]> => {
  const grouped: Record<ReviewResultType, SectionComplianceReport[]> =
    Object.values(ReviewResultType).reduce(
      (acc, type) => {
        acc[type] = [];
        return acc;
      },
      {} as Record<ReviewResultType, SectionComplianceReport[]>,
    );

  sections.forEach((section) => {
    grouped[section.reviewResultType].push(section);
  });

  return grouped;
};
